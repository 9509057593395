import { useRouter } from "next/router";
import { useMemo } from "react";
import { capitalize } from "./helpers";
const makeItem = ({
  position,
  name,
  href,
  locale
}) => ({
  "@type": "ListItem",
  position,
  name: capitalize(name),
  item: process.env.NEXT_PUBLIC_LIVE_URL + locale + href
});
const makeSchema = itemListElement => ({
  "@context": "https://schema.org",
  "@type": "BreadcrumbList",
  itemListElement
});
export function generateBreadcrumbs(asPath, locale = "en", home = "Rainbet") {
  // Remove any query parameters, as those aren't included in breadcrumbs
  const asPathWithoutQuery = asPath.split("?")[0];
  const l = locale === "en" ? "" : `/${locale}`;

  /**
   * @type {string[]}
   */
  const asPathNestedRoutes = asPathWithoutQuery.split("/").filter(v => v.length > 0 && !v.startsWith("#"));

  // Iterate over the list of nested route parts and build
  // a "crumb" object for each one.
  const crumblist = asPathNestedRoutes.map((subpath, idx) => {
    const name = subpath;
    // We can get the partial nested route for the crumb
    // by joining together the path parts up to this point.
    const href = "/" + asPathNestedRoutes.slice(0, idx + 1).join("/");
    // The title will just be the route string for now

    return {
      href,
      name,
      position: idx + 2,
      locale: l,
      title: capitalize(name)
    };
  });

  // Add in a default "Home" crumb for the top-level
  return [{
    href: "/",
    name: home,
    position: 1,
    locale: l,
    title: capitalize(home)
  }, ...crumblist];
}
export const getBreadcrumbs = (url, locale) => makeSchema(generateBreadcrumbs(url, locale).map(makeItem));
export const BreadcrumbsSchema = () => {
  const {
    asPath,
    locale
  } = useRouter();
  const breadcrumbs = useMemo(() => makeSchema(generateBreadcrumbs(asPath, locale).map(makeItem)), [locale, asPath]);
  return <script id="breadcrumbs-schema" type="application/ld+json" dangerouslySetInnerHTML={{
    __html: JSON.stringify(breadcrumbs)
  }} data-sentry-component="BreadcrumbsSchema" data-sentry-source-file="breadcrumbs.jsx"></script>;
};