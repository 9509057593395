import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { parseCookies, setCookie } from "nookies";
interface Preferences {
  miniSide: boolean;
  chatOpen: boolean;
  userBalanceType: boolean;
}
interface PreferencesContextType {
  preferences: Preferences;
  updatePreference: (key: keyof Preferences, value: boolean) => void;
}
const PreferencesContext = createContext<PreferencesContextType | undefined>(undefined);
type PreferencesProviderProps = Readonly<{
  children: React.ReactNode;
  initialPreferences: Preferences;
}>;
export function PreferencesProvider({
  children,
  initialPreferences
}: PreferencesProviderProps) {
  const [preferences, setPreferences] = useState<Preferences>(initialPreferences);
  const updatePreference = useCallback((key: keyof Preferences, value: boolean) => {
    setPreferences(prev => ({
      ...prev,
      [key]: value
    }));
    setCookie(null, key, value.toString(), {
      path: "/",
      maxAge: 365 * 24 * 60 * 60 // 1 year
    });
  }, []);

  // Sync with cookies when preferences change
  useEffect(() => {
    const cookies = parseCookies();
    setPreferences(prev => ({
      ...prev,
      miniSide: cookies.miniSide === "true",
      chatOpen: cookies.chatOpen === "true",
      userBalanceType: cookies.userBalanceType === "true"
    }));
  }, []);
  const value = useMemo(() => ({
    preferences,
    updatePreference
  }), [preferences, updatePreference]);
  return <PreferencesContext.Provider value={value} data-sentry-element="unknown" data-sentry-component="PreferencesProvider" data-sentry-source-file="PreferencesContext.tsx">
      {children}
    </PreferencesContext.Provider>;
}
export function usePreferences() {
  const context = useContext(PreferencesContext);
  if (context === undefined) {
    throw new Error("usePreferences must be used within a PreferencesProvider");
  }
  return context;
}