import { Popup } from "@/types/popup";
import PubSub from "@lib/pubsub";
import jsonHash from "@lib/tools/json-hash";

const MOBILE_DURATION = 2500;
const DEFAULT_DURATION = 5000;

const createPopup = (
  data: Popup,
  duration = DEFAULT_DURATION,
  fixedDuration: number | null = null
) =>
  jsonHash({ message: data, duration, fixedDuration }).then((id) => {
    const isMobile =
      typeof window !== "undefined" ? window.innerWidth <= 1100 : false;

    const msg = {
      id,
      duration: fixedDuration || (isMobile ? MOBILE_DURATION : duration),
      ...data,
    };

    PubSub.publish(PubSub.EVENTS.POPUP, msg);

    return id;
  });

export default createPopup;
