import usePopup from "@hooks/usePopup";
import useToken from "@hooks/useToken";
import { useUserSession } from "@hooks/useUserSession";
import { NODE_API } from "@lib/api/api";
import { getDeviceInfo } from "@lib/tools/device";
import { handleCatchPopup } from "@lib/tools/helpers";
import { pageLoadingAtom } from "@store/pageLoading";
import { useSetAtom } from "jotai";
import Script from "next/script";
import { useEffect, useState } from "react";
const GoogleSignIn = () => {
  const {
    signInToken
  } = useUserSession();
  const token = useToken();
  const setMessage = usePopup();
  const setPageLoading = useSetAtom(pageLoadingAtom);
  const [isLoaded, setIsLoaded] = useState(typeof window !== "undefined" && "google" in window);
  useEffect(() => {
    if (!isLoaded || token) {
      window?.google?.accounts?.id?.cancel?.();
      return;
    }
    const callback = async ({
      credential
    }) => {
      setPageLoading(true);
      const device = await getDeviceInfo();
      NODE_API.post("/v1/auth/google/callback_fedcm", {
        device
      }, {
        headers: {
          Authorization: `Bearer ${credential}`
        }
      }).then(r => r.data).then(async data => {
        const jwt = data?.tokens?.jwt as string;
        if (!jwt) throw new Error("No token found");
        return {
          jwt
        };
      }).then(signInToken).then(() => setMessage({
        code: "responses.successful",
        type: 1
      })).catch(handleCatchPopup(setMessage, true)).finally(() => setPageLoading(false));
    };
    window.google.accounts.id.initialize({
      client_id: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID,
      callback,
      log_level: "debug",
      use_fedcm_for_prompt: true,
      cancel_on_tap_outside: false
    });
    window.google.accounts.id.prompt();
  }, [isLoaded, setMessage, setPageLoading, signInToken, token]);
  if (token) return null;
  return <Script id="google-sign-in" src="https://accounts.google.com/gsi/client" onLoad={() => setIsLoaded(true)} data-sentry-element="Script" data-sentry-component="GoogleSignIn" data-sentry-source-file="google-sign-in.tsx" />;
};
export default GoogleSignIn;