import { useDictionary } from "@lib/tools/getDictionary";
import { useLocale } from "@lib/tools/helpers";
import store from "@store/index";
import { Provider } from "jotai";
import { Session } from "next-auth";
import { SessionProvider } from "next-auth/react";
import React, { ReactNode } from "react";
import { IntlProvider } from "react-intl";
import { RecoilRoot } from "recoil";
import { DevTools } from "jotai-devtools";
import "jotai-devtools/styles.css";

// DO NOT USE RECOIL
// DO NOT USE RECOIL

const development = process.env.NEXT_PUBLIC_LOCALHOST;
export const defaultIntercomData = {
  api_base: "https://api-iam.intercom.io",
  app_id: "w3maa0h3",
  hide_default_launcher: true
} as const;
type Props = {
  children: ReactNode;
  session: Session;
  initialDictionary: Record<string, string>;
};
const Providers = ({
  children,
  session,
  initialDictionary
}: Props) => {
  const refetchInterval = Number(process.env.NEXT_PUBLIC_MAX_TOKEN_AGE || 25 * 60);
  const locale = useLocale();
  const {
    data: dictionary
  } = useDictionary({
    locale,
    fallbackData: initialDictionary
  });
  return <IntlProvider locale={locale} messages={dictionary || {
    locale: null
  }} data-sentry-element="IntlProvider" data-sentry-component="Providers" data-sentry-source-file="Providers.tsx">
      <Provider store={store} data-sentry-element="Provider" data-sentry-source-file="Providers.tsx">
        {development === "true" && <DevTools store={store} theme="dark" />}

        <RecoilRoot data-sentry-element="RecoilRoot" data-sentry-source-file="Providers.tsx">
          <SessionProvider {...session ? {
          session
        } : {}} refetchInterval={refetchInterval - 5} data-sentry-element="SessionProvider" data-sentry-source-file="Providers.tsx">
            {children}
          </SessionProvider>
        </RecoilRoot>
      </Provider>
    </IntlProvider>;
};
export default Providers;