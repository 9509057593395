import StorageService from "@lib/services/Storage.Service";
import { memo, useEffect, useState } from "react";
import { warn } from "../lib/tools/logger";
import { turnstileLoadedAtom } from "@store/global";
import { useAtomValue, useSetAtom } from "jotai";
import Script from "next/script";
const sitekey = process.env.NEXT_PUBLIC_TURNSTILE_SITE_KEY;
const allowKey = process.env.NEXT_PUBLIC_TURNSTILE_ALLOW_KEY;
export const scriptLink = `https://challenges.cloudflare.com/turnstile/v0/api.js?onload=onLoadTurnstile`;
export const ScriptTag = memo(function ScriptTag() {
  const setLoaded = useSetAtom(turnstileLoadedAtom);
  if (typeof window !== "undefined") window.onLoadTurnstile = () => setLoaded(true);
  return <Script src={scriptLink} id="turnstile-script" onError={warn} defer={false} async={false} />;
});
const renderTurnstile = () => new Promise<string>((resolve, reject) => window.turnstile.render("#captcha-container", {
  sitekey: process.env.NEXT_PUBLIC_LOCALHOST === "true" ? allowKey : sitekey,
  callback: resolve,
  "error-callback": reject,
  "refresh-expired": "never"
}));
const Turnstile = () => {
  const loaded = useAtomValue(turnstileLoadedAtom);
  const [token, setToken] = useState<string | null>(null);
  useEffect(() => {
    if (!sitekey || !loaded) return;
    renderTurnstile().then(setToken).catch(warn);
  }, [loaded]);
  useEffect(() => {
    if (!token) return;
    StorageService.setCookieValue("turnstile_token", token);
  }, [token]);
  if (!sitekey) return null;
  return <>
      <ScriptTag data-sentry-element="ScriptTag" data-sentry-source-file="turnstile.tsx" />
      <div id="captcha-container" className="hidden" />
    </>;
};
export default Turnstile;