import { signOut } from "next-auth/react";
import { pageLoadingAtom } from "@store/pageLoading";
import store from "@store/index";
import TokenService from "@lib/services/Token.Service";
import { shutdown } from "@intercom/messenger-js-sdk";

export const resetAll = async () => {
  shutdown();

  store.set(pageLoadingAtom, true);

  signOut({ redirect: false }).finally(() => {
    TokenService.removeUser();
    localStorage.clear();
    sessionStorage.clear();
    deleteCookies();

    setTimeout(() => {
      window.location.replace("/");
    }, 2000);
  });
};

export function deleteCookies() {
  const cookies = document.cookie.split(";");

  // Get the current domain without subdomains (if any)
  const hostnameParts = window.location.hostname.split(".");
  const domainVariations = [];

  // Build possible domain variations:
  // e.g., for "sub.example.co.uk", build ".example.co.uk", ".co.uk", ".uk"
  for (let i = 0; i < hostnameParts.length; i++) {
    domainVariations.push("." + hostnameParts.slice(i).join("."));
  }

  cookies?.forEach?.((cookie) => {
    const eqPos = cookie.indexOf("=");
    // Trim cookie name
    const name = eqPos > -1 ? cookie.slice(0, eqPos).trim() : cookie.trim();

    // Delete cookie for each domain variation and path
    domainVariations.forEach((domainVariation) => {
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=${domainVariation}`;
    });

    // Also attempt to delete cookie for current host without domain attribute
    // (some cookies might not specify domain explicitly)
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
  });
}
