import type { createHash } from "crypto";

type HashBuffer = ArrayBuffer | Buffer;

const browserHash = (data: string, algorithm = "SHA-256") => {
  if (!window?.crypto?.subtle) {
    throw new Error(
      "Web Crypto API (crypto.subtle) not available in this browser."
    );
  }

  const encoder = new TextEncoder();
  const hash = encoder.encode(data);

  return window.crypto.subtle.digest(algorithm, hash);
};

const nodeHash = async (data: string, ...args: Parameters<typeof createHash>) =>
  import("crypto").then(({ createHash }) =>
    createHash(...args)
      .update(data)
      .digest()
  );

const hashBufferToHex = (buffer: HashBuffer): string =>
  Array.from(new Uint8Array(buffer))
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");

const hashJson = <T>(value: T, algorithm = "SHA-256") => {
  const hash = typeof window !== "undefined" ? browserHash : nodeHash;
  const str: string = JSON.stringify(value, null, 0);

  return hash(str, algorithm)
    .then(hashBufferToHex)
    .catch((error) => {
      console.error("Error hashing JSON value:", error);
      throw new Error(`Failed to hash JSON value: ${error.message}`);
    });
};

export default hashJson;
