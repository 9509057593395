import { NODE_API } from "@lib/api/api";
import { warn } from "@lib/tools/logger";
import { useRouter } from "next/router";
import { useEffect, useMemo } from "react";
import useSWR, { useSWRConfig } from "swr";

type PublicIPResponse = {
  ip?: string;
  country?: string;
  region?: string;
  regionName?: string;
  cityName?: string;
  proxy?: string;
  hosting?: string;
  constraint?: string;
};

export const IP_KEY = "v1/public/ip";

export const fetchPublicIP = async (headers?: Record<string, string>) =>
  NODE_API.get<PublicIPResponse>(IP_KEY, { headers }).then((res) => res.data);

export const getCurrentIp = async (): Promise<string> =>
  fetchPublicIP()
    .then((data) => data?.ip || "")
    .catch((e) => {
      warn("Failed to get IP address:", e);
      return "";
    });

export const blockedCountries = ["US", "SG", "IL", "LV"] as const;

type BlockedCountry = (typeof blockedCountries)[number];

export const useGeoLocation = () => {
  const router = useRouter();
  const { cache } = useSWRConfig();

  // if route contains /sportsbook, revalidateOnFocus should be true
  const pathIncludesSportsbook = useMemo(
    () => router.pathname.includes("/sportsbook"),
    [router.pathname]
  );

  const { data, error, isLoading, mutate } = useSWR(IP_KEY, fetchPublicIP, {
    revalidateOnFocus: pathIncludesSportsbook,
    revalidateOnReconnect: false,
    refreshWhenOffline: false,
    refreshInterval: 0,
    revalidateOnMount: !cache.get(IP_KEY)?.data,
    fallbackData: cache.get(IP_KEY)?.data,
  });

  const isBlocked = blockedCountries.includes(data?.country as BlockedCountry);

  // this is a requirement
  useEffect(() => {
    if (pathIncludesSportsbook) mutate();
  }, [mutate, pathIncludesSportsbook]);

  return {
    region: data?.region,
    countryCode: data?.country,
    isUS: data?.country === "US",
    loading: isLoading,
    data,
    error,
    isBlocked,
  };
};
