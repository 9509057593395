import Script from "next/script";
export const GOOGLE_ANALYTICS_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID;
const GoogleAnalyticsGA4 = () => GOOGLE_ANALYTICS_ID && <>
      {/* Google tag (gtag.js) */}
      <Script async src={`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_ID}`} />
      <Script id="google-analyticsGA4" dangerouslySetInnerHTML={{
    __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${GOOGLE_ANALYTICS_ID}');
        `
  }} />
      {/* End Google tag (gtag.js) */}
    </>;
export default GoogleAnalyticsGA4;