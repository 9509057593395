import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";

// remember to use this if doing server side stuff
// import { useHydrateAtoms } from 'jotai/utils'
// do not use jotai for requests, use swr instead

type Races = {
  weekly: { ending_at: string; total_rewards: number };
  monthly: { ending_at: string; total_rewards: number };
};

export const requestTimeAtom = atom<string | null>(null);
export const racesAtom = atom<Races>();
export const menuToggleAtom = atom<boolean>(false);
export const profileDropdownOpenAtom = atom<boolean>(false);
export const chatLanguageAtom = atomWithStorage<string>("chatLanguage", "en");
export const turnstileLoadedAtom = atom(false);
export const referrerAtom = atom<string>("");
