import Script from "next/script";
export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID;
const GoogleTagManagerHead = () => GTM_ID && <Script id="google-tag-manager" dangerouslySetInnerHTML={{
  __html: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${GTM_ID}');
        `
}} />;
export default GoogleTagManagerHead;