import "@styles/globals.scss";

// polyfills because of safari 10 and 11
import "core-js/es/array/find-last";
import "core-js/es/array/to-reversed";
import "core-js/es/array/to-sorted";
import "core-js/es/map";
import "core-js/es/promise";
import "core-js/es/string/includes";
import "core-js/es/string/pad-end";
import "core-js/es/string/pad-start";
import "core-js/es/string/replace-all";
import "core-js/es/string/trim-end";
import "core-js/es/string/trim-start";
import { User } from "@/types/user";
import { MobileBottomNav } from "@components/header/mobile-bottom-nav";
import IntegrationComponent from "@components/IntegrationComponents";
import GoogleSignIn from "@components/IntegrationComponents/google-sign-in";
import JotaiHydration from "@components/jotai-hydration";
import { LoadingSimpleSpinner } from "@components/Loading/LoadingSimpleSpinner";
import Providers, { defaultIntercomData } from "@components/Providers";
import { PreferencesProvider, usePreferences } from "@contexts/PreferencesContext";
import { IP_KEY } from "@hooks/useGeoLocation";
import { useShouldShowFooterFeed } from "@hooks/useShouldShowFooterFeed";
import useSocket from "@hooks/useSocket";
import useSocketManager from "@hooks/useSocketManager";
import useUser, { ALL_FILTERS, USER_KEY } from "@hooks/useUser";
import { useUserSession } from "@hooks/useUserSession";
import { BALANCE_KEY, CURRENCIES_KEY, VAULT_BALANCE_KEY, WALLET_KEY } from "@hooks/useWallet";
import Intercom, { update } from "@intercom/messenger-js-sdk";
import type { InitType } from "@intercom/messenger-js-sdk/dist/types";
import API, { NODE_API } from "@lib/api/api";
import PubSub, { EVENTS, usePubSub, useSubscribe } from "@lib/pubsub";
import StorageService from "@lib/services/Storage.Service";
import { BreadcrumbsSchema } from "@lib/tools/breadcrumbs";
import { getDeviceInfo } from "@lib/tools/device";
import { DICTIONARY_KEY, useDictionary } from "@lib/tools/getDictionary";
import { scrollTop, useLocale } from "@lib/tools/helpers";
import { logError } from "@lib/tools/logger";
import { useNextCssRemovalPrevention } from "@lib/tools/useNextCSSFix";
import { cn } from "@lib/utils";
import { ErrorBoundary } from "@sentry/nextjs";
import { racesAtom, referrerAtom, requestTimeAtom } from "@store/global";
import { pageLoadingAtom } from "@store/pageLoading";
import { useAtom, useSetAtom } from "jotai";
import { useHydrateAtoms } from "jotai/utils";
import { motion } from "motion/react";
import { Session } from "next-auth";
import App, { AppProps } from "next/app";
import dynamic from "next/dynamic";
import { Montserrat, Oswald } from "next/font/google";
import Head from "next/head";
import { useRouter as useNav, usePathname } from "next/navigation";
import { useRouter } from "next/router";
import Script from "next/script";
import { parseCookies } from "nookies";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import ReactGA from "react-ga4";
import useSWR, { SWRConfig, useSWRConfig } from "swr";
import MicrosoftClarity from "./MicrosoftClarity";
import Tracking from "./Tracking ";
import Turnstile from "./turnstile";
const production = process.env.NEXT_PUBLIC_PRODUCTION;
const font = Montserrat({
  subsets: ["latin"],
  fallback: ["sans-serif"],
  preload: true,
  variable: "--main-font"
});
const oswald = Oswald({
  subsets: ["latin"],
  weight: ["300", "400", "700"],
  variable: "--oswald-font"
});
const ChatContainer = dynamic(() => import("@components/chat/chat-container"));
const IntercomCustomButton = dynamic(() => import("@components/IntercomCustomButton"));
const Popup = dynamic(() => import("../components/Popup/Popup"));
const NewHeader = dynamic(() => import("../components/header"));
const Footer = dynamic(() => import("../components/Footer"));
const Sidebar = dynamic(() => import("../components/sidebar"));
const Modal = dynamic(() => import("@components/new-modal").then(mod => mod.Modal));
const enable_logs = process.env.NEXT_PUBLIC_ENABLE_LOGS;

// https://github.com/vercel/next.js/issues/33286
// https://github.com/vercel/next.js/issues/17464#issuecomment-1537262075
const applyPreloadStylesheetLinks = () => {
  const preloadStylesheetLinks = document.querySelectorAll<HTMLLinkElement>("link[rel='preload'][as='style']");
  preloadStylesheetLinks.forEach(preloadLink => {
    const existingStylesheetLink = document.querySelector(`link[rel='stylesheet'][href='${preloadLink.href}']`);
    if (!existingStylesheetLink) {
      const stylesheetLink = document.createElement("link");
      stylesheetLink.rel = "stylesheet";
      stylesheetLink.href = preloadLink.href;
      document.head.appendChild(stylesheetLink);
    }
  });
};
const idfn = () => ({});
const loadingSpinner = <div data-loading-spinner className="absolute inset-0 bg-primary-background-color max-h-[100svh] z-[10000]">
    <div className="flex justify-center items-center w-full h-full">
      <LoadingSimpleSpinner className="h-14 w-14 animate-spin text-white" />
    </div>
  </div>;
type MyAppProps = AppProps<{
  raffleData: unknown;
  requestTime: string;
  races: unknown;
  isMobile: boolean;
  dictionary: Record<string, string>;
  providers: unknown[];
  wallet: unknown;
  currencies: unknown;
  balances: unknown;
  userData: User;
  session: Session;
  ipData: unknown;
  initialReferrer: string;
  initialPreferences: {
    miniSide: boolean;
    chatOpen: boolean;
    userBalanceType: boolean;
  };
  navigationState: {
    currentUrl: string;
    referer: string;
    documentReferer: string;
    isInternalNavigation: boolean;
    isFromGoogle: boolean;
    timestamp: number;
  };
  referralCode: string;
}>;
function MyApp({
  Component,
  pageProps
}) {
  const locale = useLocale();
  const {
    cache
  } = useSWRConfig();

  // Hydrate atoms with navigation state
  useHydrateAtoms([[requestTimeAtom, pageProps.requestTime ?? Date.now()], [racesAtom, pageProps.races], [referrerAtom, pageProps.navigationState.referer || ""]] as any);

  // dict cache
  if (pageProps.dictionary && !cache.get(DICTIONARY_KEY(locale))?.data) cache.set(DICTIONARY_KEY(locale), {
    data: pageProps.dictionary
  });
  if (pageProps?.ipData && !cache.get(IP_KEY)?.data) cache.set(IP_KEY, {
    data: pageProps.ipData
  });
  if (pageProps.userData && (typeof window === "undefined" || !cache.get(USER_KEY(ALL_FILTERS))?.data)) cache.set(USER_KEY(ALL_FILTERS), {
    data: pageProps.userData
  });
  if (pageProps.wallet && (typeof window === "undefined" || !cache.get(WALLET_KEY)?.data)) cache.set(WALLET_KEY, {
    data: pageProps.wallet
  });
  if (pageProps.currencies && (typeof window === "undefined" || !cache.get(CURRENCIES_KEY)?.data)) cache.set(CURRENCIES_KEY, {
    data: pageProps.currencies
  });
  const balanceType = pageProps.initialUserBalanceType ? "promotional" : "primary" as const;
  if (pageProps.balances && (typeof window === "undefined" || !cache.get(BALANCE_KEY(balanceType))?.data)) cache.set(BALANCE_KEY(balanceType), {
    data: pageProps.balances[balanceType]
  });
  if (pageProps.balances && (typeof window === "undefined" || !cache.get(VAULT_BALANCE_KEY)?.data)) cache.set(VAULT_BALANCE_KEY, {
    data: pageProps.balances.vault
  });

  // you cannot easily update the recoil state outside of a component
  // so this is a workaround to pass the init dictionary to the components e.g. Language.jsx
  if (!PubSub.state[EVENTS.DICT]) PubSub.publishSync(EVENTS.DICT, pageProps.dictionary);
  if (!PubSub.state[EVENTS.PROVIDERS]) PubSub.publishSync(EVENTS.PROVIDERS, pageProps.providers);
  if (!PubSub.state[EVENTS.MOBILE]) PubSub.publishSync(EVENTS.MOBILE, pageProps.isMobile);
  if (!PubSub.state[EVENTS.RACES]) PubSub.publishSync(EVENTS.RACES, pageProps.races);
  const session = useUserSession();
  const {
    data: userData,
    mutate
  } = useUser();
  const sports = usePubSub(EVENTS.IS_SPORTSBOOK);
  const activeGA = useRef(false);
  const caseBattlesSocket = useSocketManager();
  const shouldShowFooter = useShouldShowFooterFeed();
  const router = useRouter();
  const {
    replace
  } = useNav();
  const pathname = usePathname();
  const userCurrency = session?.userData?.currency?.code || "USD";
  const [isLoading, setPageLoading] = useAtom(pageLoadingAtom);
  const setRaces = useSetAtom(racesAtom as any);
  const {
    isLoading: isDictionaryLoading
  } = useDictionary({
    locale
  });
  const {
    preferences,
    updatePreference
  } = usePreferences();
  const previousUrlRef = useRef(router.asPath);
  const isMobileLandingPage = useMemo(() => pathname?.includes("ph-mobile"), [pathname]);
  const races = useSWR(["v1/public/active-races", userCurrency], ([url, currency]) => NODE_API.get(url, {
    params: {
      currency
    }
  }).then(r => r.data), {
    fallbackData: pageProps.races,
    revalidateOnMount: !pageProps?.races,
    revalidateOnFocus: false,
    refreshInterval: 60 * 60 * 1000,
    keepPreviousData: true
  });
  useEffect(() => {
    setRaces(races.data);
  }, [races.data, setRaces]);
  const clearUrlQueries = useCallback(() => replace(pathname, {
    scroll: true
  }), [pathname, replace]);
  useSubscribe(EVENTS.REMOVE_QUERY, clearUrlQueries);
  useEffect(() => {
    const {
      stag
    } = router.query;
    if (stag && typeof stag === "string") {
      StorageService.setAffiliateTag(stag);
      // Remove stag from URL while preserving other query parameters
      const params = new URLSearchParams(window.location.search);
      params.delete("stag");
      const newQuery = params.toString();
      const newPath = newQuery ? `${pathname}?${newQuery}` : pathname;
      replace(newPath);
    }
  }, [router.query, pathname, replace]);
  useEffect(() => {
    if (typeof window !== "undefined") {
      try {
        const logs = enable_logs.split(",").filter(log => log);
        const shouldEnable = logs.some(log => pathname.includes(`/${log}`));
        console.log = shouldEnable ? window.log : () => {};
      } catch {}
    }
  }, [pathname]);
  useEffect(() => {
    router.events.on("routeChangeComplete", applyPreloadStylesheetLinks);
    return () => {
      router.events.off("routeChangeComplete", applyPreloadStylesheetLinks);
    };
  }, [router.events]);

  // logging buildTime to help with staging deployments
  useEffect(() => {
    console.info(`%c>>>>>> Build Time: ${new Date(document.body.dataset.buildTime).toLocaleString(navigator.language || navigator?.["userLanguage"])} <<<<<<`, "color: #e8e5ff; font-size: 1rem; font-family: var(--main-font);");
  }, []);
  useEffect(() => {
    const measurementId = process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID;
    if (measurementId && production !== "false") {
      activeGA.current = true;
      ReactGA.initialize(measurementId);
    } else if (production === "true") {
      logError("Google Analytics Measurement ID is missing or not in production");
    }
  }, []);

  //Send page view to Google Analytics
  useEffect(() => {
    if (activeGA.current) ReactGA.send({
      hitType: "pageview",
      page: router.pathname
    });
  }, [router.pathname]);

  // redirect to casino if user is logged in and on home page
  useEffect(() => {
    if (session?.token && (router.pathname === "/" || router.pathname === `/${locale}`)) {
      router.push("/casino");
    }
  }, [locale, router, session?.token]);

  //Changes event listener for IOS devices
  useEffect(() => {
    if (typeof window !== "undefined") {
      const ua = navigator.userAgent;
      if (ua.match(/iPad|iPhone|iPod/)) {
        window.click = "touchstart";
      }
    }
  }, []);
  useEffect(() => {
    getDeviceInfo();
  }, []);

  // Remove or comment out the route change handlers and replace with this simplified version
  const onRouteChangeStart = useCallback((url?: string) => {
    if (!url) return;
    const currentUrl = previousUrlRef.current;
    const currentParams = new URLSearchParams(currentUrl.split("?")[1]);
    const newParams = new URLSearchParams(url.split("?")[1]);

    // Check if closing a modal (had modal param, losing it)
    const wasModal = currentParams.has("modal");
    const isClosingModal = wasModal && !newParams.has("modal");

    // Skip loading for modal closures and existing conditions
    if (isClosingModal || newParams.has("modal")) {
      previousUrlRef.current = url; // Update ref before exiting
      return;
    }
    setPageLoading(true);
  }, [session?.token, locale, setPageLoading]);
  const onRouteChangeComplete = useCallback((url: string) => {
    previousUrlRef.current = url; // Update previous URL reference
    setPageLoading(false);
    if (window.innerWidth < 1100) {
      updatePreference("chatOpen", false);
    }
    scrollTop();
  }, [setPageLoading, updatePreference]);
  const onRouteChangeError = useCallback(() => {
    setPageLoading(false);
  }, [setPageLoading]);
  useEffect(() => {
    router.events.on("routeChangeStart", onRouteChangeStart);
    router.events.on("routeChangeComplete", onRouteChangeComplete);
    router.events.on("routeChangeError", onRouteChangeError);
    return () => {
      router.events.off("routeChangeStart", onRouteChangeStart);
      router.events.off("routeChangeComplete", onRouteChangeComplete);
      router.events.off("routeChangeError", onRouteChangeError);
    };
  }, [onRouteChangeComplete, onRouteChangeError, onRouteChangeStart, router.events]);
  const chatSocket = useSocket(process.env.NEXT_PUBLIC_CHAT_SOCKET_URL);
  const userLanguage = userData?.preferences?.language;
  useEffect(() => {
    if (!userLanguage) return;
    if (userLanguage === locale) return;
    API.post("user/update-settings", {
      language: locale
    }).then(() => mutate());
  }, [locale, mutate, userLanguage]);
  const intercomData: InitType = useMemo(() => userData?.intercom?.user_hash ? {
    ...defaultIntercomData,
    name: userData.username,
    // Full name
    email: userData.email,
    // Email address
    user_id: userData.intercom.external_id,
    user_hash: userData.intercom.user_hash,
    created_at: new Date(userData.profile.registered_at).getTime()
  } : defaultIntercomData, [userData?.email, userData?.intercom?.external_id, userData?.intercom?.user_hash, userData?.profile?.registered_at, userData?.username]);
  useEffect(() => {
    Intercom(intercomData);
    // run only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    update(intercomData);
  }, [intercomData]);
  useEffect(() => {
    if (typeof window !== "undefined") document.body.style.setProperty("--main-font", font.style.fontFamily);
  }, []);
  const isPageLoading = isLoading || isDictionaryLoading;
  return <div className={cn(font.className, "site-container")} style={{
    "--main-font": font.style.fontFamily,
    "--oswald-font": oswald.style.fontFamily,
    "--vh": "calc(100vh * 0.01)"
  } as React.CSSProperties} data-sentry-component="MyApp" data-sentry-source-file="_app.tsx">
      <IntegrationComponent.FacebookPixel data-sentry-element="unknown" data-sentry-source-file="_app.tsx" />
      <IntegrationComponent.GoogleAnalyticsGA4 data-sentry-element="unknown" data-sentry-source-file="_app.tsx" />
      <IntegrationComponent.GoogleTagManagerBody data-sentry-element="unknown" data-sentry-source-file="_app.tsx" />
      <IntegrationComponent.GoogleTagManagerHead data-sentry-element="unknown" data-sentry-source-file="_app.tsx" />
      {!session?.token && <GoogleSignIn />}

      <Head data-sentry-element="Head" data-sentry-source-file="_app.tsx">
        {/* Regular favicons */}
        <link rel="icon" type="image/png" sizes="64x64" href="https://cdn.rainbet.com/brand/favicons/64x64.png" />
        <link rel="icon" type="image/png" sizes="128x128" href="https://cdn.rainbet.com/brand/favicons/128x128.png" />
        <link rel="icon" type="image/png" sizes="192x192" href="https://cdn.rainbet.com/brand/favicons/192x192.png" />

        {/* Apple touch icons */}
        <link rel="apple-touch-icon" sizes="120x120" href="https://cdn.rainbet.com/brand/favicons/120x120.png" />
        <link rel="apple-touch-icon" sizes="152x152" href="https://cdn.rainbet.com/brand/favicons/152x152.png" />
        <link rel="apple-touch-icon" sizes="180x180" href="https://cdn.rainbet.com/brand/favicons/180x180.png" />
      </Head>

      <Modal data-sentry-element="Modal" data-sentry-source-file="_app.tsx" />
      <Popup data-sentry-element="Popup" data-sentry-source-file="_app.tsx" />

      <div className="relative min-h-svh grid grid-rows-[68px_auto]">
        <NewHeader data-sentry-element="NewHeader" data-sentry-source-file="_app.tsx" />

        {/* Body Area - Updated to Grid */}
        <div className="grid [@media(min-width:1101px)]:grid-cols-[auto_1fr_auto] flex-1" id="mainBody">
          <Sidebar miniSide={preferences.miniSide} data-sentry-element="Sidebar" data-sentry-source-file="_app.tsx" />

          {/* Content Area */}
          <motion.div className={cn("body-content-container h-full relative [@media(max-width:1100px)]:pb-[68px]", isPageLoading ? "overflow-hidden h-[calc(100svh-68px)]" : "overflow-auto")} transition={{
          duration: 0.3,
          ease: "easeInOut"
        }} data-sentry-element="unknown" data-sentry-source-file="_app.tsx">
            {isPageLoading ? loadingSpinner : null}

            <div className={cn("relative inner-body-container", router.pathname !== "/" && router.pathname !== "/casino" && "max-w-[1200px] w-full mx-auto p-4")}>
              <Component {...pageProps} setLoading={idfn} caseBattlesSocket={caseBattlesSocket} data-sentry-element="Component" data-sentry-source-file="_app.tsx" />

              {!sports && <IntercomCustomButton chatOpen={preferences.chatOpen} />}
            </div>

            {!isMobileLandingPage && <Footer feed={shouldShowFooter} />}
          </motion.div>

          {/* Chat Area - Will collapse when chat is false */}
          <ChatContainer isMod={userData?.chat?.moderator || false} chatSocket={chatSocket} chatOpen={preferences.chatOpen} data-sentry-element="ChatContainer" data-sentry-source-file="_app.tsx" />
        </div>

        <MobileBottomNav data-sentry-element="MobileBottomNav" data-sentry-source-file="_app.tsx" />
      </div>
    </div>;
}

// Add this helper function at the top of the file
const getHeaderState = async (headers: any) => {
  try {
    const stateHeader = headers?.["x-navigation-state"];
    if (!stateHeader) return {};
    const parsedState = JSON.parse(stateHeader);
    return {
      currentUrl: parsedState.currentUrl || "",
      referer: parsedState.referer || "",
      documentReferer: parsedState.documentReferer || "",
      isInternalNavigation: !!parsedState.isInternalNavigation,
      isFromGoogle: !!parsedState.isFromGoogle,
      timestamp: parsedState.timestamp || Date.now()
    };
  } catch (e) {
    console.error("Failed to parse navigation state:", e);
    return {};
  }
};

// Update getInitialProps
// Add getInitialProps to AppWrapper
AppWrapper.getInitialProps = async appContext => {
  const appProps = await App.getInitialProps(appContext);
  const cookies = parseCookies(appContext.ctx);

  // Get navigation state with default fallback
  const navigationState = {
    currentUrl: "",
    referer: "",
    documentReferer: "",
    isInternalNavigation: false,
    isFromGoogle: false,
    timestamp: Date.now(),
    ...(await getHeaderState(appContext.ctx?.req?.headers).catch(() => ({})))
  };
  return {
    ...appProps,
    pageProps: {
      ...appProps.pageProps,
      navigationState,
      referralCode: cookies.referral_code || null,
      initialPreferences: {
        miniSide: cookies.miniSide === "true",
        chatOpen: cookies.chatOpen === "true",
        userBalanceType: cookies.userBalanceType === "true"
      }
    }
  };
};
export default function AppWrapper(props: MyAppProps) {
  // very important to prevent css removal, do not remove
  useNextCssRemovalPrevention();
  return <ErrorBoundary data-sentry-element="ErrorBoundary" data-sentry-component="AppWrapper" data-sentry-source-file="_app.tsx">
      <SWRConfig value={{
      provider: () => new Map()
    }} data-sentry-element="SWRConfig" data-sentry-source-file="_app.tsx">
        <Providers session={props.pageProps.session} initialDictionary={props.pageProps.dictionary} data-sentry-element="Providers" data-sentry-source-file="_app.tsx">
          <PreferencesProvider initialPreferences={props.pageProps.initialPreferences} data-sentry-element="PreferencesProvider" data-sentry-source-file="_app.tsx">
            {production !== "false" && <Script src={process.env.NEXT_PUBLIC_MOUSEFLOW_URL} />}

            {production !== "false" && <MicrosoftClarity />}
            <BreadcrumbsSchema data-sentry-element="BreadcrumbsSchema" data-sentry-source-file="_app.tsx" />
            <Tracking data-sentry-element="Tracking" data-sentry-source-file="_app.tsx" />
            <Turnstile data-sentry-element="Turnstile" data-sentry-source-file="_app.tsx" />
            <JotaiHydration raffleData={props.pageProps.raffleData} data-sentry-element="JotaiHydration" data-sentry-source-file="_app.tsx" />
            <MyApp {...props} data-sentry-element="MyApp" data-sentry-source-file="_app.tsx" />
          </PreferencesProvider>
        </Providers>
      </SWRConfig>
    </ErrorBoundary>;
}