import axios from "axios";
import axiosRetry from "axios-retry";
import TokenService from "../services/Token.Service";
import { REFRESH_TOKEN } from "../tools/customEvents";
import PubSub from "../pubsub";
import { debounce } from "../tools/helpers";
import { log } from "@lib/tools/logger";
import { logout } from "@hooks/useUserSession";
import { addTurnstileToken } from "./turnstile";

const debounce_refresh = debounce(() => {
  PubSub.publishSync(REFRESH_TOKEN.type, true);
}, 750);

// Add auth token to headers
const addTokenToHeader = (config) => {
  if ("Authorization" in config.headers) return config;

  try {
    const token = TokenService.getLocalAccessToken();
    if (!token.length) return config;

    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${token}`,
    };

    return config;
  } catch (error) {
    delete config.headers["Authorization"];
    return config;
  }
};

const API = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  withCredentials: true,
});

const isServer = typeof window === "undefined";

axiosRetry(API, {
  retries: isServer ? 1 : 20,
  retryDelay: (retryCount) => {
    const time = 1000 * retryCount;
    log(`Retry #${retryCount} in ${time}ms`);
    return time;
  },
  retryCondition: (error: any) => {
    if (error?.response?.data?.error === "er_login_restricted") {
      logout();
      return false;
    }

    if (error.response?.status < 400) return false;

    if (error.response?.status === 401) {
      debounce_refresh();
      return true;
    }

    return false;
  },
  onRetry: async (retryCount, error, config) => {
    delete config.headers["Authorization"];
    await addTokenToHeader(config);
    await addTurnstileToken(config);
  },
});

API.interceptors.request.use(
  async (config) => {
    // Add auth token first
    config = await addTokenToHeader(config);
    // Then add Turnstile token
    config = await addTurnstileToken(config);
    return config;
  },
  (error) => Promise.reject(error)
);

export default API;
export { default as NODE_API } from "./nodeApi";
