// base: The basic information of the user;
// profile: The profile object attribute;
// settings: Extra information to show on settings page;
// wallet: Adds extra information to the resulting payload related to wallet contents;
// affiliate: Adds extra information to the resulting payload related to affiliate contents;
// promotion: Adds the promotion object to the resulting payload;

import { Filters, User } from "@/types/user";
import { NODE_API } from "@lib/api/api";
import { resetAll } from "@lib/tools/reset";
import { useMemo } from "react";
import useSWR, {
  SWRConfiguration,
  unstable_serialize,
  useSWRConfig,
} from "swr";
import useToken from "./useToken";

export const ALL_FILTERS: Filters[] = [
  "base",
  "profile",
  "settings",
  "wallet",
  "affiliate",
  "promotion",
];

export const getUser = async (
  token: string,
  filters: Filters[] = [],
  headers: Record<string, string> = {}
) => {
  const filter = filters?.join(",") || ALL_FILTERS.join(",");

  const params = new URLSearchParams({
    filter,
  });

  return NODE_API.get<User>(`v1/user?${params.toString()}`, {
    headers: { Authorization: `Bearer ${token}`, ...headers },
  })
    .then((res) => res.data)
    .then((user) => {
      if (user?.deleted) {
        try {
          resetAll();
        } catch {
          throw new Error("user_deleted");
        }
      }

      return user;
    });
};

export type Options = {
  filters?: Filters[];
  swr?: SWRConfiguration;
  headers?: Record<string, string>;
  debug?: boolean;
};

export const USER_KEY = (filters: Filters[]) =>
  unstable_serialize(["v1/user", filters]);

const useUser = (
  options: Options = {
    filters: ALL_FILTERS,
  }
) => {
  const { cache } = useSWRConfig();
  const token = useToken();
  const key = USER_KEY(options.filters);

  const { data, mutate, ...rest } = useSWR<User, User, SWRConfiguration>(
    token ? key : null,
    () => getUser(token, options.filters, options.headers),
    {
      keepPreviousData: true,
      fallbackData: cache.get(key)?.data,
      revalidateOnMount: !cache.get(key)?.data,
      ...options.swr,
    }
  );

  const hasUserData = useMemo(() => {
    try {
      return Object.keys(data).length > 0;
    } catch {
      return false;
    }
  }, [data]);

  return {
    data,
    mutate,
    hasUserData,
    ...rest,
  };
};

export default useUser;
