import { useHydrateAtoms } from "jotai/utils";

//Raffle
import { raffleDetailsAtomBase, userRaffleDataAtomBase } from "@Games/Raffle/jotai-raffle";
type JotaiHydrationProps = {
  raffleData: any;
};
export default function JotaiHydration({
  raffleData
}: JotaiHydrationProps) {
  useHydrateAtoms([[raffleDetailsAtomBase, raffleData?.raffle], [userRaffleDataAtomBase, {
    ticketCount: raffleData?.user?.tickets?.length
  }]] as any);
  return null;
}