import PubSub from "@lib/pubsub";
import { useRouter } from "next/router";
import { usePathname, useSearchParams } from "next/navigation";
import { useCallback, useEffect, useId, useRef, useState } from "react";
import { logError } from "./logger";

export const useCustomId = (name) => `${name}-${useId().replaceAll(":", "")}`;

export const objCopy = (obj) =>
  JSON.parse(JSON.stringify(obj, Object.getOwnPropertyNames(obj)));

export const getValidSubdomain = (host) => {
  let subdomain = null;
  if (!host && typeof window !== "undefined") {
    // On client side, get the host from window
    host = window.location.host;
  }
  if (host && host.includes(".")) {
    const candidate = host.split(".")[0];
    if (candidate && !candidate.includes("localhost")) {
      // Valid candidate
      subdomain = candidate;
    }
  }
  return subdomain;
};

export const useLocale = () => {
  const { locale } = useRouter(); // does not work with next/navigation
  return locale || "en";
};

export const generateRandomId = () => {
  return Math.random().toString(36).substring(2, 13);
};

export const numFormatNoRound = (amount, decimal = 2) => {
  const factor = Math.pow(10, decimal);
  return (Math.floor(amount * factor) / factor).toFixed(decimal);
};

export const numFormat = (amount, decimal = 2, locale = "en-US") => {
  return new Intl.NumberFormat(locale, {
    minimumFractionDigits: decimal,
    maximumFractionDigits: decimal,
  }).format(amount);
};

export const removeUrlQueries = () =>
  PubSub.publishSync(PubSub.EVENTS.REMOVE_QUERY);

export function round2Decimal(num) {
  return (+(Math.round(num + "e+2") + "e-2")).toFixed(2);
}

export const toCurrency = (amount) => {
  return round2Decimal(amount / 100).toLocaleString();
};

export function capitalize(slug = "") {
  var words = slug.split("-");

  for (var i = 0; i < words.length; i++) {
    var word = words[i];
    words[i] = word.charAt(0).toUpperCase() + word.slice(1);
  }

  return words.join(" ");
}

export function debounce(func, wait, immediate) {
  let timeout;
  return function () {
    let context = this,
      args = arguments;
    let later = function () {
      timeout = null;
      if (!immediate) return func.apply(context, args);
    };
    let callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) return func.apply(context, args);
  };
}

export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export function throttle(func, wait, options) {
  var context, args, result;
  var timeout = null;
  var previous = 0;
  if (!options) options = {};
  var later = function () {
    previous = options.leading === false ? 0 : Date.now();
    timeout = null;
    result = func.apply(context, args);
    if (!timeout) context = args = null;
  };
  return function () {
    var now = Date.now();
    if (!previous && options.leading === false) previous = now;
    var remaining = wait - (now - previous);
    context = this;
    args = arguments;
    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      previous = now;
      result = func.apply(context, args);
      if (!timeout) context = args = null;
    } else if (!timeout && options.trailing !== false) {
      timeout = setTimeout(later, remaining);
    }
    return result;
  };
}

export function clearAllTimeouts() {
  let id = window.setTimeout(function () {}, 0);
  while (id--) {
    window.clearTimeout(id); // will do nothing if no timeout with id is present
  }
}

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export function parseJwt(token) {
  if (!token) return null;

  try {
    // Split the token into its parts
    const base64Url = token.split(".")[1];
    // Replace '-' with '+' and '_' with '/' for base64 decoding
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    // Decode base64 and parse JSON
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  } catch (e) {
    logError("Error decoding JWT", e);
    return null;
  }
}

export function useThrottle(cb, delay) {
  const options = { leading: true, trailing: false }; // add custom lodash options
  const cbRef = useRef(cb);
  // use mutable ref to make useCallback/throttle not depend on `cb` dep
  useEffect(() => {
    cbRef.current = cb;
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(
    throttle((...args) => cbRef.current(...args), delay, options),
    [delay]
  );
}

// group array into n parts
export function groupBy(arr, n) {
  return arr.reduce((acc, item, index) => {
    const i = index % n;
    if (!acc[i]) {
      acc[i] = [];
    }
    acc[i].push(item);
    return acc;
  }, []);
}

// split array into n parts
export function splitBy(arr, n) {
  const len = arr.length;
  const out = [];
  let i = 0;
  while (i < len) {
    out.push(arr.slice(i, (i += Math.ceil((len - i) / n--))));
  }
  return out;
}

export const chunkEveryN = (arr, n) =>
  Array.from({ length: Math.ceil(arr.length / n) }, (_, i) =>
    arr.slice(i * n, i * n + n)
  );

// fn that transforms timestamp into "Feb 22, 2024"
export const formatDateMonthDayYear = (timestamp, locale = "en") => {
  return new Date(timestamp).toLocaleDateString(locale, {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
};

export const formatDateAndTime = (unixTimestamp, lang) => {
  if (!unixTimestamp) return;
  // Set default language
  let language = "pt-BR";
  switch (lang) {
    case "pt":
      language = "pt-BR";
      break;
    case "en":
      language = "en-US";
      break;
  }

  // Create a Date object from the Unix timestamp
  const date = new Date(unixTimestamp);

  // Create an Intl.DateTimeFormat instance for the date
  const dateFormatter = new Intl.DateTimeFormat(language, {
    month: "short",
    day: "numeric",
    year: "numeric",
  });

  let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Create an Intl.DateTimeFormat instance for the time
  const timeFormatter = new Intl.DateTimeFormat(language, {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
    timeZone: timeZone,
  });

  // Format the date and time using the specified language
  const localizedDate = dateFormatter.format(date);
  const localizedTime = timeFormatter.format(date);

  return {
    localizedDate,
    localizedTime,
  };
};

// // Usage
// const item = { date: '2023-09-13' };
// const lang = 'en';
// const { localizedDate, localizedTime } = formatDateAndTime(item, lang);

// console.log(`Localized Date: ${localizedDate}`);
// console.log(`Localized Time: ${localizedTime}`);

// Format the date

// export const numFormat = (amount, decimal = 2) => {
//   const factor = Math.pow(10, decimal);
//   return (Math.floor(amount * factor) / factor).toFixed(decimal);
// }

export function cutOff(num) {
  return +(Math.floor(num + "e+2") + "e-2");
}

export function trimThirdDecimalPlace(num) {
  let local = parseFloat(num);
  var splitNum = local.toString().split(".");
  if (splitNum[1] && splitNum[1].length >= 3) {
    if (splitNum[1][2] !== "0") {
      return local;
    } else {
      return local.toFixed(2);
    }
  }
  return local.toFixed(2);
}

export function trimToTwoDecimalPlaces(num) {
  let local = parseFloat(num);
  return (Math.floor(local * 100) / 100).toFixed(2);
}

export function formatNumber(num) {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "B";
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  }
  return num;
}

export function countdownClock(timestamp, targetDuration) {
  const currentTime = Math.floor(Date.now() / 1000);
  const targetTime = timestamp + targetDuration;
  const timeDiff = targetTime - currentTime;

  if (timeDiff <= 0) return false;

  let seconds = timeDiff;
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);
  let days = Math.floor(hours / 24);

  let countdown = "";
  if (days > 0) countdown += `${days}d `;
  if (hours > 0) countdown += `${hours % 24}h `;
  if (minutes > 0) countdown += `${minutes % 60}m `;
  countdown += `${seconds % 60}s`;

  return countdown.trim(); // remove trailing space
}

export const stringFormat = (str, ...args) =>
  str.replace(/{(\d+)}/g, function (match, number) {
    return typeof args[number] != "undefined" ? args[number] : match;
  });

export function countdownClockFormatted(timestamp, targetDuration) {
  const currentTime = Math.floor(Date.now() / 1000);
  const targetTime = timestamp + targetDuration;
  const timeDiff = targetTime - currentTime;

  if (timeDiff <= 0) return false;

  let seconds = timeDiff;
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);
  let days = Math.floor(hours / 24);

  if (days >= 1) {
    return `${days} ${days === 1 ? "day" : "days"}`;
  }

  if (hours >= 1) {
    return `${hours} ${hours === 1 ? "hour" : "hours"}`;
  }

  if (minutes >= 1) {
    return `${minutes} ${minutes === 1 ? "minute" : "minutes"}`;
  }

  return `${seconds} ${seconds === 1 ? "second" : "seconds"}`;
}

export function getLatestRank(ranks, total) {
  let latestRank = null;

  // Iterate through the ranks data to find the latest rank that matches the condition
  for (let i = 0; i < ranks.length; i++) {
    const rank = ranks[i];
    if (Number(total) >= Number(rank.threshold)) {
      latestRank = rank.name;
    } else {
      break;
    }
  }

  return latestRank;
}

export const classnames = (style, ...names) => ({
  className: names
    .reduce((acc, name) => {
      if (style[name]) {
        acc += style[name] + " ";
      }
      return acc;
    }, "")
    .trim(),
});

export function syncTimeCalc(originalEndTime, syncTime) {
  let adjustedEndTime;

  // Check if the client's clock is ahead of the server's time
  if (syncTime > 0) {
    // console.log("sync my clock is ahead of the server's time");
    // Adjust the end time by subtracting the sync time
    adjustedEndTime = originalEndTime + syncTime;
  } else {
    // console.log("sync my clock is behind the server's time");
    // Adjust the end time by adding the absolute value of the sync time
    adjustedEndTime = originalEndTime - Math.abs(syncTime);
  }

  return adjustedEndTime;
}

// {...mergeClasses(classnames(st, "asdf"), classnames(st, "asdf2"), "asdf3")}
export const mergeClasses = (...classnames) => ({
  className: classnames
    .map((c) => c?.className || c || "")
    .filter((c) => c)
    .join(" ")
    .trim(),
});

export function toggleFullScreen(iframe) {
  if (!document.fullscreenElement) {
    if (iframe.requestFullscreen) {
      iframe.requestFullscreen();
    } else if (iframe.msRequestFullscreen) {
      iframe.msRequestFullscreen(); // IE/Edge
    } else if (iframe.mozRequestFullScreen) {
      iframe.mozRequestFullScreen(); // Firefox
    } else if (iframe.webkitRequestFullscreen) {
      iframe.webkitRequestFullscreen(); // Chrome, Safari, and Opera
    }
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen(); // IE/Edge
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen(); // Firefox
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen(); // Chrome, Safari, and Opera
    }
  }
}

/**
 * Generates a redirect URL based on the provided domain and path.
 *
 * @param {"main" | "blog"} domain - The domain to redirect to ("main" or "blog").
 * @param {string} path - The path to append to the domain.
 * @returns {string} The generated redirect URL.
 */
export const resolveDomain = (domain, path = "") => {
  switch (domain) {
    case "main":
      return `${process.env.NEXT_PUBLIC_DOMAIN}/${path}`;

    case "blog":
      return `${process.env.NEXT_PUBLIC_DOMAIN_BLOG}/${path}`;
  }
};

export const isDomain = (domain) => {
  if (typeof window === "undefined") return false;

  switch (domain) {
    case "main":
      return window.location.hostname === process.env.NEXT_PUBLIC_DOMAIN;

    case "blog":
      return window.location.hostname === process.env.NEXT_PUBLIC_BLOG_DOMAIN;
  }
};

export const isClient = () =>
  typeof window !== "undefined" && typeof document !== "undefined";

export const getDevicePixelRatio = () => {
  if (typeof window !== "undefined") {
    return window.devicePixelRatio || 1;
  }
  return 1; // Default to 1 if not on the client
};

export const isMobileUserAgent = () => {
  if (typeof navigator === "undefined") return false;

  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
};

/**
 * @returns {string | undefined}
 */
export const getClientIp = ({ req }) => {
  const forwarded =
    req?.headers?.["x-forwarded-for"] || req?.headers?.["x-real-ip"];
  const ip = forwarded
    ? forwarded?.split(/, ?/)?.[0]
    : req?.connection?.remoteAddress;

  if (
    ip === "::1" ||
    ip === "127.0.0.1" ||
    ip === "localhost" ||
    ip === "0.0.0.0"
  )
    return undefined;

  return ip;
};

export const scrollTop = () => {
  document?.querySelector("#__next")?.scrollTo(0, 0);
};

export const useControllableState = (
  controlledValue = null,
  defaultValue = null,
  onChange = null
) => {
  const isControlled = controlledValue !== null;
  const [valueState, setValue] = useState(defaultValue);

  const value = isControlled ? controlledValue : valueState;

  const setValueIfUncontrolled = useCallback(
    (newValue) => {
      if (!isControlled) setValue(newValue);
    },
    [isControlled]
  );

  useEffect(() => {
    if (typeof onChange === "function") onChange(value);
  }, [value, onChange]);

  return [value, setValueIfUncontrolled];
};

export const useAppendSearch = (keepHistory = false) => {
  const search = useSearchParams();
  const { replace, push } = useRouter();
  const redirect = keepHistory ? push : replace;

  const append = useCallback(
    (key, value) => {
      redirect(
        {
          query: {
            ...Object.fromEntries([...search.entries()]),
            [key]: value,
          },
        },
        undefined,
        {
          shallow: true,
        }
      );
    },
    [redirect, search]
  );

  return append;
};

export const useRemoveSearch = (keepHistory = false) => {
  const search = useSearchParams();
  const pathname = usePathname();
  const { replace, push } = useRouter();
  const redirect = keepHistory ? push : replace;

  const remove = useCallback(
    (key) => {
      const params = new URLSearchParams(search);
      params.delete(key);

      const str = params.toString().length > 0 ? `?${params.toString()}` : "";

      redirect(`${pathname}${str}`, undefined, { shallow: true });
    },
    [pathname, redirect, search]
  );

  return remove;
};

/**
 * @example promise.catch(handleCatchPopup(setMessage))
 * @param {*} setter - setMessage from usePopup
 * @param {boolean} shouldShowDefaultError - boolean if should show "network error", usually not required
 * @param {*} e - the catch error
 */
export const handleCatchPopup =
  (setter, shouldShowDefaultError = false) =>
  (e) => {
    if (!e) {
      return null;
    }

    const defaultError = shouldShowDefaultError ? "er_network" : null;
    const msg =
      e?.response?.data?.error || e?.error || e?.data?.error || defaultError;

    const code = `responses.${msg}`;
    const type = 0;

    if (!msg) {
      logError("handleCatchPopup", "No error message found in response", e);
      return;
    }

    return setter({ code, type });
  };

const currencySymbols = {
  aud: "A$",
  brl: "R$",
  cad: "C$",
  eur: "€",
  gbp: "£",
  usd: "$",
  jpy: "¥",
};

export const getCurrencySymbol = (code = "usd") => {
  return currencySymbols[code.toLowerCase()] || "$";
};
