import { useCallback, useEffect, useState } from "react";
import io, { Socket } from "socket.io-client";
import { useUserSession } from "./useUserSession";
import { log } from "@lib/tools/logger";
type RainbetSocket = Socket & {
  auth: {
    token: string;
  };
};
const makeSocket = ({
  namespace,
  publicId
}) => {
  return io(namespace, {
    withCredentials: true,
    path: "/socket.io",
    transports: ["websocket"],
    secure: true,
    extraHeaders: {
      "X-Request-With": "HttpFront",
      "x-public-id": publicId
    },
    auth: {},
    query: {}
  }) as RainbetSocket;
};
const sockets: Record<string, RainbetSocket> = {};

/**
 * Custom hook for managing a socket connection.
 *
 * @param namespace - The namespace for the socket connection.
 */
export default function useSocket(namespace?: string) {
  const [socket, setSocket] = useState(sockets[namespace] || null);
  const {
    token,
    publicId
  } = useUserSession();
  useEffect(() => {
    if (!namespace) return;
    const skt = makeSocket({
      namespace,
      publicId
    });
    setSocket(() => skt);
    sockets[namespace] = skt;
  }, [namespace, publicId]);
  useEffect(() => {
    if (!token) return;
    // do not update the socket if the token exists already
    if (socket?.auth?.token) return;
    setSocket(s => {
      if (!s) return s;
      s.disconnect();
      s.io.opts.query.token = token;
      s.auth.token = token;
      s.connect();
      return s;
    });
  }, [socket?.auth?.token, token]);
  useEffect(() => {
    if (!socket) return;
    socket.on("connect", () => log(`Socket ${namespace} connected`, sockets));
    socket.on("disconnect", () => log(`Socket ${namespace} disconnected`, sockets));
    return () => {
      socket.off("connect");
      socket.off("disconnect");
    };
  }, [namespace, socket]);
  return socket;
}
export const useEvent = (socket, event, callback) => {
  useEffect(() => {
    socket?.on(event, callback);
    return () => {
      socket?.off(event, callback);
    };
  }, [socket, event, callback]);
};

// eslint-disable-next-line no-unused-vars
type Handler = (item: GameHistory) => void;
export const useNewGameHistory = (handler: Handler) => {
  const socket = useSocket(`${process.env.NEXT_PUBLIC_API_NODE_URL}/game-history`);
  useEvent(socket, "new-history", handler);
};
export const useWinsFeed = ({
  limit = 12,
  initialState = null,
  handleMerge = (() => true) as any
}) => {
  const socket = useSocket(`${process.env.NEXT_PUBLIC_API_NODE_URL}/game-history`);
  const [state, setState] = useState(initialState);
  const onNewHistory = useCallback(item => {
    setState(prev => {
      if (!prev?.length) return prev;
      if (!handleMerge({
        data: item
      })) return prev;
      const isUnique = prev.every(i => i.id !== item.id);
      if (!isUnique) return prev;
      return [item, ...prev].slice(0, limit);
    });
  }, [handleMerge, limit]);
  useEffect(() => {
    setState(initialState);
  }, [initialState]);
  useEffect(() => {
    socket?.on("new-history", onNewHistory);
    return () => {
      socket?.off("new-history", onNewHistory);
    };
  }, [onNewHistory, socket]);
  const data = state?.slice?.(0, limit) || null;
  return {
    data,
    socket
  };
};
export interface GameHistory {
  id: number | string;
  currencyAmount: number;
  currency: string;
  value: number;
  currencyPayout: number;
  payout: string;
  multiplier: string;
  updatedAt: string;
  gameParameters?: GameParameters;
  user: User;
  game: Game;
  completed?: boolean;
}

// hard to type this because api returns different types of parameters for each game
export type GameParameters = any;
export interface User {
  id: number | string;
  publicId: string;
  username: string;
  publicProfile: number;
  __betRank__?: BetRank;
  rankLevel?: RankLevel;
}
export interface BetRank {
  name: string;
  level: number;
}
export interface RankLevel {
  name: string;
  level: number;
}
export interface Game {
  id: number | string;
  url: string;
  name: string;
  icon: string;
  iconMini: any;
  customBanner?: string;
}