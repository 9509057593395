import createClient from "openapi-fetch";
import type { paths } from "@services";

if (!process.env.NEXT_PUBLIC_API_NODE_URL) {
  throw new Error("NEXT_PUBLIC_API_NODE_URL is not set");
}

const services = createClient<paths, "application/json">({
  baseUrl: process.env.NEXT_PUBLIC_API_NODE_URL,
});

export type Endpoints = keyof paths;

export type Endpoint<T extends Endpoints> = paths[T];

export default services;
