/**
 * @file This file is now deprecated and will be removed in a future version.
 * @deprecated Use the new `useMessages` hook instead.
 */

import { warn } from "@lib/tools/logger";
import React, { createElement, HTMLAttributes, memo, ReactNode, useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { defaultRichTexts, FormatMessageArgs } from "./use-messages";
type Dict = {
  [key: string]: string | Dict;
};

// empty object to fix reference issues
const emptyObj = {} as Record<string, unknown>;
type DictProps = {
  section?: string[] | string;
  as?: string;
  name: string;
  replacements?: Record<string, unknown>;
} & HTMLAttributes<HTMLElement>;

/**
 * @deprecated use useIntl().formatMessage instead
 */
export const Dict = memo<DictProps>(function Dict({
  section: section_ = "",
  as = "p",
  name,
  replacements = emptyObj,
  ...props
}) {
  const t = useLanguageLegacy(section_);
  const propsText = useMemo(() => {
    const __html = t(name, replacements as Record<string, string>);
    return {
      ...(typeof __html === "string" ? {
        dangerouslySetInnerHTML: {
          __html
        }
      } : {
        children: __html
      }),
      ...props
    };
  }, [t, name, replacements, props]);
  return useMemo(() => createElement(as, propsText), [as, propsText]);
});

/**
 * @deprecated use useIntl().formatMessage instead
 */
export const useDict = (section: string | string[] = "common") => {
  const sections = typeof section === "string" ? section.split(" ") : section || "";
  return useCallback((props: DictProps) => <Dict section={section} {...props} />,
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [sections?.toString()]);
};

/**
 * @deprecated use useIntl().formatMessage instead
 * @template { string } T
 * @param { string } section
 * @param  {...T} keys
 * @returns { Record<T, string> }
 */
export const useDictionary = (section, ...keys) => {
  const L = useLanguageLegacy(section);
  const o = useMemo(() => keys.reduce((acc, key) => {
    acc[key] = L(key);
    return acc;
  }, {}), [L, keys]);
  return o;
};
type GetMessage = (key_: string, replacements?: FormatMessageArgs[1], opts?: FormatMessageArgs[2]) => string | ReactNode[];

/**
 * This a function to convert legacy language code to useIntl.formatMessage
 * @deprecated use useIntl().formatMessage instead
 */
export function useLanguageLegacy(section: string | string[] = ""): GetMessage {
  const sections = typeof section === "string" ? section.split(" ") : section;
  const {
    formatMessage,
    messages
  } = useIntl();
  const getID = useCallback((key: string) => {
    if (!sections?.length) return null;
    return sections.map(s => `${s}_${key}`).findLast(msg => msg in messages);
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [messages, sections.toString()]);
  const getMessage = useCallback<GetMessage>(function getMessage(key_, replacements, opts) {
    try {
      const vs = {
        ...defaultRichTexts,
        ...replacements
      };
      const key = key_.replace(/\./g, "_");
      if (key in messages) return formatMessage({
        id: key
      }, vs, opts);
      const id = getID(key);
      if (!id && typeof window !== "undefined") warn(`Could not find id "${key}" \n`);
      return id ? formatMessage({
        id
      }, vs, opts) : key;
    } catch (e) {
      warn(e);
      return key_;
    }
  }, [formatMessage, getID, messages]);
  return getMessage;
}
export default useLanguageLegacy;