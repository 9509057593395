import { warn, logError } from "@lib/tools/logger";
import { getCurrentIp } from "@hooks/useGeoLocation";
import { parseCookies, setCookie, destroyCookie } from "nookies";

const COOKIE_KEY = "cf_clearance";
const IP_COOKIE_KEY = "cf_clearance_ip";
const TIMESTAMP_COOKIE_KEY = "cf_clearance_timestamp";
const COOKIE_EXPIRY = 7 * 24 * 60 * 60; // 7 days in seconds
const CHECK_INTERVAL = 100; // 100ms between checks

/**
 * Parse Turnstile cookie and metadata
 */
export const parseTurnstileCookie = (): {
  value: string;
  ip: string;
  timestamp: number;
} | null => {
  const cookies = parseCookies();
  const cookie = cookies[COOKIE_KEY];
  const storedIp = cookies[IP_COOKIE_KEY];
  const storedTimestamp = cookies[TIMESTAMP_COOKIE_KEY];

  if (!cookie) return null;

  return {
    value: cookie,
    ip: storedIp || "",
    timestamp: storedTimestamp ? parseInt(storedTimestamp) : Date.now(),
  };
};

/**
 * Check if cookie needs refresh
 */
const needsRefresh = async (cookie: {
  value: string;
  ip: string;
  timestamp: number;
}): Promise<boolean> => {
  // Check expiration
  if (Date.now() - cookie.timestamp > COOKIE_EXPIRY * 1000) {
    return true;
  }

  // Check IP change
  const currentIp = await getCurrentIp();
  if (currentIp && cookie.ip && currentIp !== cookie.ip) {
    return true;
  }

  return false;
};

/**
 * Wait for valid cf_clearance cookie with timeout
 */
export const waitForClearance = async (
  timeout = 250
): Promise<string | null> => {
  return new Promise(async (resolve) => {
    const startTime = Date.now();
    let lastCookie: { value: string; ip: string; timestamp: number } | null =
      null;

    const checkCookie = async () => {
      // Get current cookie
      const cookie = parseTurnstileCookie();

      if (cookie) {
        // If we have a previous cookie, check if it needs refresh
        if (lastCookie) {
          const shouldRefresh = await needsRefresh(lastCookie);
          if (shouldRefresh) {
            // Force a new challenge
            destroyCookie(null, COOKIE_KEY);
            destroyCookie(null, IP_COOKIE_KEY);
            destroyCookie(null, TIMESTAMP_COOKIE_KEY);
            lastCookie = null;
            setTimeout(checkCookie, CHECK_INTERVAL);
            return;
          }
        }

        // Get current IP if not set
        if (!cookie.ip) {
          const currentIp = await getCurrentIp();
          if (currentIp) {
            cookie.ip = currentIp;
            setCookie(null, IP_COOKIE_KEY, currentIp, {
              maxAge: COOKIE_EXPIRY,
              path: "/",
            });
            setCookie(null, TIMESTAMP_COOKIE_KEY, Date.now().toString(), {
              maxAge: COOKIE_EXPIRY,
              path: "/",
            });
          }
        }

        lastCookie = cookie;
        resolve(cookie.value);
        return;
      }

      if (Date.now() - startTime > timeout) {
        //warn("Timeout waiting for cf_clearance cookie");
        resolve(null);
        return;
      }

      // Check again in 100ms
      setTimeout(checkCookie, CHECK_INTERVAL);
    };

    checkCookie();
  });
};

/**
 * Add Turnstile token to headers
 */
export const addTurnstileToken = async (config: any) => {
  try {
    // Wait for valid cf_clearance cookie
    const clearance = await waitForClearance();

    if (clearance) {
      config.headers = {
        ...config.headers,
        "X-Turnstile-Token": clearance,
      };
    } else {
      // warn("No valid cf_clearance cookie available for request");
    }
  } catch (error) {
    logError("Error adding Turnstile token to request:", error);
  }

  return config;
};

/**
 * Force refresh of Turnstile token
 */
export const forceRefreshTurnstile = async (): Promise<void> => {
  if (typeof window === "undefined") return;

  // Remove all related cookies
  destroyCookie(null, COOKIE_KEY);
  destroyCookie(null, IP_COOKIE_KEY);
  destroyCookie(null, TIMESTAMP_COOKIE_KEY);

  // Wait for new cookie
  await waitForClearance();
};
