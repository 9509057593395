import _PubSub from "pubsub-js";
import { useCallback, useEffect, useState } from "react";

export const EVENTS = {
  REFRESH: "REFRESH",
  PROVIDERS: "PROVIDERS",
  LOG: "LOG",
  DICT: "DICT",
  LOCALE: "LOCALE",
  MOBILE: "MOBILE",
  SCROLL: "SCROLL",
  REMOVE_QUERY: "REMOVE_QUERY",
  IS_SPORTSBOOK: "IS_SPORTSBOOK",
  REQUEST_TIME: "REQUEST_TIME",
  RACES: "RACES",
  POPUP: "POPUP",
} as const;

export type PubSubEvent = keyof typeof EVENTS;
export type SubscriptionListener<T> = (
  message: PubSubEvent,
  data?: T
) => unknown;

export const useSubscribe = <T>(
  event: PubSubEvent,
  callback: SubscriptionListener<T>
) => {
  useEffect(() => {
    const token = _PubSub.subscribe(event, callback);

    return () => {
      _PubSub.unsubscribe(token);
    };
  }, [event, callback]);
};

class Stateful {
  EVENTS = EVENTS;

  /**
   * @type { Record<string, any> }
   */

  state = {};

  publish = (event, data) => {
    this.state[event] = data;
    _PubSub.publish(event, data);
  };

  publishSync = (event, data) => {
    this.state[event] = data;
    _PubSub.publishSync(event, data);
  };

  subscribe = _PubSub.subscribe;
  unsubscribe = _PubSub.unsubscribe;
}

const PubSub = new Stateful();

export const usePubSub = (
  event: PubSubEvent,
  defaultValue = PubSub.state[event]
) => {
  const [value, setValue] = useState(PubSub.state[event] || defaultValue);

  useEffect(() => {
    setValue((prev) => {
      if (prev === PubSub.state[event]) return prev;
      return PubSub.state[event];
    });
  }, [event]);

  const onEvent = useCallback((_, data) => {
    setValue(data);
  }, []);

  useEffect(() => {
    PubSub.subscribe(event, onEvent);

    return () => {
      PubSub.unsubscribe(onEvent);
    };
  }, [event, onEvent]);

  return value;
};

if (typeof window !== "undefined") {
  window.addEventListener(
    "scroll",
    (e) => {
      PubSub.publish(EVENTS.SCROLL, e);
    },
    true
  );
}

export default PubSub;
