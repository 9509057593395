import Script from "next/script";
import { useEffect, useState } from "react";
const ANJ_SEAL_ID = "b2ba2232-9d9f-4eed-868f-62dfae496dd4";
const divId = `anj-${ANJ_SEAL_ID}`;
const key = divId.replaceAll("-", "_");

// https://rainbet.atlassian.net/browse/RB-1044
const AnjSeal = props => {
  const [isLoaded, setIsLoaded] = useState(typeof window !== "undefined" && key in window);
  useEffect(() => {
    if (!isLoaded) return;
    window?.[key]?.init();
  }, [isLoaded]);
  return <>
      <Script src={`https://${ANJ_SEAL_ID}.snippet.anjouangaming.org/anj-seal.js`} onLoad={() => setIsLoaded(true)} data-sentry-element="Script" data-sentry-source-file="AnjSeal.jsx" />
      <div id={divId} data-anj-seal-id={ANJ_SEAL_ID} data-anj-image-size="64" data-anj-image-type="basic-small" {...props}></div>
    </>;
};
export default AnjSeal;