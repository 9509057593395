import DictionarySchema from "@dictionary";
import objectEntries from "@lib/tools/object-entries";
import React, { ReactNode, useCallback, useMemo } from "react";
import { defineMessage, IntlShape, MessageDescriptor, useIntl } from "react-intl";
export const defaultRichTexts = {
  span: (node: ReactNode) => <span>{node}</span>,
  b: (node: ReactNode) => <b>{node}</b>,
  p: (node: ReactNode) => <p>{node}</p>,
  br: () => <br />,
  i: (node: ReactNode) => <i>{node}</i>,
  strong: (node: ReactNode) => <strong>{node}</strong>,
  ol: (node: ReactNode) => <ol>{node}</ol>,
  ul: (node: ReactNode) => <ul>{node}</ul>,
  li: (node: ReactNode) => <li>{node}</li>
} as const;
export const getMessage = <K extends keyof DictionarySchema,>(key: K, opt?: MessageDescriptor) => {
  const k = typeof key === "string" ? key : `${key}`;
  return defineMessage({
    id: k,
    description: k,
    ...opt
  });
};
export type FormatMessage = IntlShape["formatMessage"];
export type FormatMessageArgs = Parameters<FormatMessage>;
export type Options = FormatMessageArgs[2];
export type ReplacementOptions = {
  [key: string | number | symbol]: string | number | ReactNode | MessageDescriptor | Options;
  messageOptions?: MessageDescriptor;
  formatOptions?: Options;
};
export type DictionaryMessageSchema = { [key in keyof DictionarySchema]?: ReplacementOptions };
export type SchemaResult<T> = { [key in keyof T]: string | ReactNode[] };

/**
 * Generates a dictionary of translated messages from a schema definition
 * @template T - Schema type extending base dictionary message structure
 * @param schema - Message schema defining keys, replacements and format options
 * @param deps - Like useEffect, if any of the values in the dependencies change, the dictionary will be rerendered.
 * @example
 * const dict = useMessagesFromSchema({
 *   responses_su_reward_chest: {
 *     TOKEN_AMOUNT: 100,
 *     CALENDAR_REWARD: 100
 *   },
 *   homepage_banner_title: {}
 * });
 */
export const useMessagesFromSchema = <T extends DictionaryMessageSchema,>(schema: T, deps: React.DependencyList = []): SchemaResult<T> => {
  const {
    formatMessage
  } = useIntl();
  const result = useMemo(() => {
    const entries = objectEntries(schema);
    return entries.reduce((acc, [key, value]) => {
      const {
        messageOptions,
        formatOptions,
        ...values
      } = (value || {}) as ReplacementOptions;
      const message = getMessage(key as keyof DictionarySchema, messageOptions);
      const formattedMessage = formatMessage(message, {
        ...defaultRichTexts,
        ...values
      }, formatOptions);
      acc[key] = formattedMessage;
      return acc;
    }, {} as SchemaResult<T>);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formatMessage, ...deps]);
  return result;
};

/**
 * Hook that provides a function to format messages from the dictionary
 * @returns A function that takes a dictionary key, replacements, and format options to return formatted message
 * @example
 * const format = useMessages();
 * const message = format('common_button_submit', { count: 5 });
 */
export default function useMessages(): (key: keyof DictionarySchema, replacements?: FormatMessageArgs[1], opts?: FormatMessageArgs[2]) => string | ReactNode[] {
  const {
    formatMessage
  } = useIntl();
  return useCallback((key, values, opts) => {
    const message = getMessage(key);
    return formatMessage(message, values, opts);
  }, [formatMessage]);
}