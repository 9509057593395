import { CurrencyRates } from "@/types";
import { usePreferences } from "@contexts/PreferencesContext";
import { NODE_API } from "@lib/api/api";
import NodeAPI from "@lib/api/nodeApi";
import { logError } from "@lib/tools/logger";
import { dynamicBalanceAtom, fakeBalanceAtom, inPlayAtom } from "@store/wallet";
import { useAtom } from "jotai";
import { useHydrateAtoms } from "jotai/utils";
import { useEffect, useMemo, useRef } from "react";
import useSWR, { useSWRConfig } from "swr";
import useToken from "./useToken";
import useUser from "./useUser";

export interface Currency {
  code: string;
  prepend: string;
  append: string;
  rate: number;
  decimals: number;
}

export interface Balance {
  amount: number;
  currency: Currency;
}

const defaultRate = {
  rate: 1,
  display: {
    isDefault: true,
    prepend: "$",
    append: null,
    icon: "https://cdn.rainbet.com/currencies%2Fusd.svg",
  },
};

export const WALLET_KEY = "v1/user/wallet";
export const CURRENCIES_KEY = "v1/public/currencies";
export const BALANCE_KEY = (type: "promotional" | "primary") =>
  `v1/user/balance/${type}`;
export const VAULT_BALANCE_KEY = "v1/user/balance/vault";

export interface Wallet {
  active: Active;
  displayed?: any[];
}

interface Active {
  currency: string;
  promotional: string;
  primary: string;
  vault: string;
}
export const fetchWallet = ([url, token], headers = {}) =>
  NodeAPI.get<Wallet>(url || "v1/user/wallet", {
    headers: {
      Authorization: `Bearer ${token}`,
      ...headers,
    },
  }).then((res) => res.data);

export const fetchUserBalance = (
  [, token, type],
  headers = {}
): Promise<Balance> =>
  NODE_API.get(`v1/user/balance/${type ? "promotional" : "primary"}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      ...headers,
    },
  }).then((res) => {
    return res.data;
  });

export const fetchCurrencies = () =>
  NODE_API.get("v1/public/currencies").then((res) => res.data);

export const fetchBalanceVault = (
  [url, token],
  headers = {}
): Promise<Balance> =>
  NodeAPI.get(url || "v1/user/balance/vault", {
    headers: {
      Authorization: `Bearer ${token}`,
      ...headers,
    },
  }).then((res) => res.data);

export const useCurrencies = () => {
  const { cache } = useSWRConfig();

  return useSWR<CurrencyRates>(
    CURRENCIES_KEY,
    async () => {
      const response = await fetchCurrencies();

      if ("_k" in response) delete response._k;

      return response;
    },
    {
      revalidateOnFocus: false,
      revalidateOnMount: !cache.get(CURRENCIES_KEY)?.data,
      revalidateOnReconnect: false,
      keepPreviousData: true,
      refreshInterval: 600000,
      fallbackData: (() => {
        const cachedData = { ...cache.get(CURRENCIES_KEY)?.data };
        if (!cachedData) return undefined;

        if ("_k" in cachedData) delete cachedData._k;

        return cachedData;
      })(),
    }
  );
};

export const useVaultBalance = () => {
  const { cache } = useSWRConfig();
  const token = useToken();
  const hasToken = !!token;

  return useSWR<Balance>(
    hasToken ? VAULT_BALANCE_KEY : null,
    (url) => fetchBalanceVault([url, token], {}),
    {
      revalidateOnFocus: false,
      revalidateOnMount: !cache.get(VAULT_BALANCE_KEY)?.data,
      fallbackData: cache.get(VAULT_BALANCE_KEY)?.data,
    }
  );
};

export const useBalance = () => {
  const { cache } = useSWRConfig();
  const token = useToken();
  const hasToken = !!token;
  const { preferences } = usePreferences();
  const balanceType = preferences.userBalanceType ? "promotional" : "primary";

  return useSWR<Balance>(
    hasToken ? BALANCE_KEY(balanceType) : null,
    (url) => fetchUserBalance([url, token, preferences.userBalanceType], {}),
    {
      revalidateOnFocus: false,
      revalidateOnMount: !cache.get(BALANCE_KEY(balanceType))?.data,
      fallbackData: cache.get(BALANCE_KEY(balanceType))?.data,
    }
  );
};

export const useWalletList = () => {
  const { data: userData, hasUserData } = useUser();
  const initialCheckDone = useRef(false);
  const { preferences, updatePreference } = usePreferences();
  const { cache } = useSWRConfig();
  const token = useToken();
  const hasToken = !!token;
  const currencies = useCurrencies();
  const balanceType = preferences.userBalanceType ? "promotional" : "primary";
  const { mutate: mutateBalance } = useBalance();

  const walletList = useSWR(
    hasToken && hasUserData ? WALLET_KEY : null,
    async (url) => {
      const data = await fetchWallet([url, token], {});
      const balances = splitWallet(data, currencies.data);

      if (!balances) {
        mutateBalance();
        return data;
      }

      // Only check user balance type on first mount
      if (!initialCheckDone.current) {
        const loggedUserBalanceType =
          (userData?.preferences?.user_balance_type === true &&
            data.active.promotional !== undefined) ??
          preferences.userBalanceType;

        // set user balance type to true if it is true and promotional is undefined
        if (loggedUserBalanceType) {
          updatePreference("userBalanceType", true);
        } else if (
          userData?.preferences?.user_balance_type === true &&
          data.active.promotional === undefined
        ) {
          updatePreference("userBalanceType", false);
        }
        initialCheckDone.current = true;
      }

      // update the cache with the new balances
      cache.set(BALANCE_KEY(balanceType), {
        data: preferences.userBalanceType
          ? balances.promotional
          : balances.primary,
        error: null,
        isLoading: false,
        isValidating: false,
      });
      cache.set(VAULT_BALANCE_KEY, {
        data: balances.vault,
        error: null,
        isLoading: false,
        isValidating: false,
      });

      return data;
    },
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateOnMount: !cache.get(WALLET_KEY)?.data,
      keepPreviousData: true,
      fallbackData: cache.get(WALLET_KEY)?.data,
    }
  );

  return walletList;
};

export default function useWallet() {
  const { preferences, updatePreference } = usePreferences();
  const [inPlay, setInPlay] = useAtom(inPlayAtom);
  const [dynamicBalance, setDynamicBalance] = useAtom(dynamicBalanceAtom);
  const [fakeBalance, setFakeBalance] = useAtom(fakeBalanceAtom);
  const setUserActiveBalance = setFakeBalance;
  const lockedBalance = dynamicBalance;

  const walletBalanceList = useWalletList();
  const currencies = useCurrencies();
  const balance = useBalance();
  const vaultBalance = useVaultBalance();
  const { data: userBalance, mutate: balanceMutate } = balance;

  const { data: currenciesData } = currencies;

  useHydrateAtoms([[fakeBalanceAtom, userBalance?.amount]]);

  const userActiveBalance = useMemo(
    () => (dynamicBalance ? fakeBalance : userBalance?.amount || 0),
    [dynamicBalance, fakeBalance, userBalance?.amount]
  );

  const activeRate: CurrencyRates[keyof CurrencyRates] = useMemo(
    () => currenciesData?.[userBalance?.currency?.code] || defaultRate,
    [currenciesData, userBalance?.currency?.code]
  );

  const rawBalance = useMemo(() => userBalance?.amount, [userBalance?.amount]);

  useEffect(() => {
    if (dynamicBalance) return;
    setFakeBalance(userActiveBalance);
  }, [dynamicBalance, setFakeBalance, userActiveBalance]);

  const isLoading =
    walletBalanceList.isLoading ||
    balance.isLoading ||
    vaultBalance.isLoading ||
    currencies.isLoading ||
    walletBalanceList.isValidating ||
    balance.isValidating ||
    vaultBalance.isValidating ||
    currencies.isValidating;

  return {
    currencies: currenciesData,
    lockedBalance,
    balance: userActiveBalance,
    activeBalance: userActiveBalance,
    userActiveBalance,
    promoBalance: walletBalanceList?.data?.active?.promotional || 0,
    setInPlay,
    inPlay,
    balanceMutate,
    userBalanceType: preferences.userBalanceType,
    setUserBalanceType: (value: boolean) =>
      updatePreference("userBalanceType", value),
    dynamicBalance,
    setDynamicBalance,
    setUserActiveBalance,
    setLockBalance: setDynamicBalance,
    userBalance,
    vaultBalance: vaultBalance.data,
    vaultBalanceMutate: vaultBalance.mutate,
    walletBalanceList,
    walletBalanceListMutate: walletBalanceList?.mutate,
    walletSetting: userBalance?.currency?.code || "USD",
    activeRate,
    fakeBalance,
    rawBalance,
    isLoading,
  };
}

export function splitWallet(wallet: Wallet, currencies: CurrencyRates) {
  try {
    const currencyCode = wallet?.active?.currency || "USD";
    const currency: Currency = {
      ...currencies[currencyCode]?.display,
      code: currencyCode,
    };

    const primary: Balance = {
      amount: +wallet?.active?.primary || 0,
      currency,
    };
    const promotional: Balance = {
      amount: +wallet?.active?.promotional || 0,
      currency,
    };
    const vault: Balance = {
      amount: +wallet?.active?.vault || 0,
      currency,
    };

    return { primary, promotional, vault, currency };
  } catch (error) {
    logError("Error splitting wallet", error);
    return null;
  }
}
