import { cn } from "@lib/utils";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";
const buttonVariants = cva("relative inline-flex items-center justify-center gap-2 border border-solid whitespace-nowrap rounded-md text-[13px] transition-all duration-150 ease-in-out focus-visible:outline-none disabled:pointer-events-none disabled:opacity-[0.333] [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0 overflow-hidden z-0 [&:disabled]:cursor-not-allowed [&:disabled]:select-none", {
  variants: {
    variant: {
      disabled: `text-white/30 border-transparent`,
      none: `text-white border-transparent`,
      gold: `
          bg-gradient-to-b from-[#D67B10] to-[#A65F0C] border-[#F4B56C]
          before:absolute before:inset-0 before:bg-gradient-to-b before:from-[#F4B56C] before:to-[#A65F0C]
          before:opacity-50 before:transition-opacity before:duration-500 hover:before:opacity-100 before:-z-10`,
      blue: `
          bg-gradient-to-b from-[#0077DB] to-[#0098EB] border-[#3D9EEF]
          before:absolute before:inset-0 before:bg-gradient-to-b before:from-[#3D9EEF] before:to-[#176BC6]
          before:opacity-50 before:transition-opacity before:duration-500 hover:before:opacity-100 before:-z-10`,
      orange: `
          bg-gradient-to-b from-[#D67B10] to-[#A65F0C]/10 border-[#F4B56C]
          before:absolute before:inset-0 before:bg-gradient-to-b before:from-[#F4B56C] before:to-[#A65F0C]
          before:opacity-50 before:transition-opacity before:duration-500 hover:before:opacity-100 before:-z-10`,
      green: `
          bg-gradient-to-b from-[#6A9749] to-[#6BD719] border-[#8BFF33]
          before:absolute before:inset-0 before:bg-gradient-to-b before:from-[#5F8641] before:to-[#60C016]
          before:opacity-0 before:transition-opacity before:duration-500 hover:before:opacity-100 before:-z-10`,
      normal: `
          bg-[#262C50] border-[#33395C] hover:bg-[#2A2F52] hover:border-[#454D7D] transition-colors duration-150`,
      success: `
          border-[rgba(100,180,37,0.15)] bg-gradient-to-b from-[rgba(22,217,76,0.15)] to-[rgba(16,162,57,0.15)] text-[#64b425]
          hover:from-[rgba(22,217,76,0.3)] hover:to-[rgba(16,162,57,0.3)] hover:text-[#7cc639]
          [&_svg]:fill-[#64b425] [&_path]:fill-[#64b425]
          transition-colors duration-150`,
      loading: `
          bg-gradient-to-b from-[#9CA2C4]/5 to-[#5E679E]/5 border-white/5
          hover:from-[#B0B5D6]/10 hover:to-[#7078AF]/10 transition-colors duration-150`,
      dark: `bg-[#212747] border-[#33395C] hover:bg-[#2A2F52] hover:border-[#39406F] aria-[selected=true]:bg-[#313963] aria-[selected=true]:border-[#454D7D] transition-colors duration-150`,
      "simple-green": "bg-[#5BB616] border-[#7DD934] hover:bg-[#509F13] hover:border-[#7DD934] transition-colors duration-150",
      "simple-blue": "bg-[#0077DB] border-[#1F99FF] hover:bg-[#006CC7] hover:border-[#52B0FF] transition-colors duration-150",
      ghost: `bg-transparent border-transparent hover:bg-[#212747] hover:border-[#33395C] transition-colors duration-150`,
      "ghost-blue": `bg-[#0077db] shadow-[0px_0px_8px_0px_rgba(0,119,219,0.20)] shadow-[inset_0px_0px_12px_0px_rgba(255,255,255,0.35)] border-[#3c9dee] hover:bg-[#084586] transition-colors duration-150`,
      "ghost-orange": `bg-[#d67b10] rounded-md shadow-[0px_0px_8px_0px_rgba(0,119,219,0.20)] shadow-[inset_0px_0px_12px_0px_rgba(255,255,255,0.35)] border-[#f4b56b] hover:bg-[#a65f0c] transition-colors duration-150`
    },
    size: {
      default: "min-h-[50px] px-10 py-2",
      sm: "h-[33px] rounded-md px-[10px] [&_svg]:w-[12px]"
    }
  },
  defaultVariants: {
    variant: "blue",
    size: "default"
  }
});
export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}
const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({
  className,
  variant,
  size,
  asChild = false,
  disabled,
  ...props
}, ref) => {
  const Comp = asChild ? Slot : "button";
  return <Comp className={cn(buttonVariants({
    variant,
    size,
    className
  }))} ref={ref} disabled={disabled} aria-disabled={disabled} {...props} />;
});
Button.displayName = "Button";
export type ButtonVariant = NonNullable<VariantProps<typeof buttonVariants>["variant"]>;
export { Button, buttonVariants };