import services from "@lib/api/services.client";
import useSWR, { unstable_serialize, useSWRConfig } from "swr";

const DICTIONARY_URL = "/v1/public/translations";

export const DICTIONARY_KEY = (language: string) =>
  unstable_serialize([DICTIONARY_URL, language]);

const _getDictionary = (language: string, headers?: Record<string, string>) =>
  services
    .GET(DICTIONARY_URL, {
      params: {
        query: {
          language,
        } as never, // api is not typed here
      },
      headers,
    })
    .then((res) => res.data as Record<string, string>);

type useDictionaryOpts = {
  locale: string;
  fallbackData?: Record<string, string>;
};

export const useDictionary = (opts: useDictionaryOpts) => {
  const { locale, fallbackData } = opts;
  const { cache } = useSWRConfig();
  const key = DICTIONARY_KEY(locale);

  const dictCache = cache.get(key) || { data: fallbackData };

  const swr = useSWR(key, () => _getDictionary(locale), {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    keepPreviousData: true,
    fallbackData: dictCache?.data,
    revalidateOnMount: !dictCache?.data,
    refreshInterval: 1000 * 60 * 10, // 10 minutes
  });

  return swr;
};

type CacheItem = {
  data: Record<string, string>;
  timestamp: number;
};

const cache: Record<string, CacheItem> = {};
// 10 mins for prod, 1min for dev
const cacheTime =
  process.env.NEXT_PUBLIC_APP_ENV === "production" ? 1000 * 60 * 10 : 1000;

type Opts = {
  forceRefresh?: boolean;
  headers?: Record<string, string>;
};

const getDictionary = async (language: string, opts: Opts = {}) => {
  const { forceRefresh = false, headers } = opts;

  const cached = cache[language];

  if (cached && cached.timestamp > Date.now() - cacheTime && !forceRefresh) {
    return cached.data;
  }

  const data = await _getDictionary(language, headers);
  cache[language] = { data, timestamp: Date.now() };

  return data;
};

export default getDictionary;
